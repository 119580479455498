import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page not found" />
    <div className="max-w-xl mx-auto px-4 min-h-screen mt-12">
      <h1 className="text-5xl font-bold font-display text-center">Lost in Space</h1>
      <p className="text-xl text-center mt-2">You just hit a route that doesn&#39;t exist... Please try it again.</p>
    </div>
  </Layout>
)

export default NotFoundPage
